@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap"); */

@layer components {
  .btn-primary {
    @apply px-8 py-2 rounded-full font-bold text-white focus:outline-none;
  }
}
/*
html {
  color: #fff;
} */

/* hide arrows when set input type="number"  */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

[data-reach-dialog-content] {
  width: 50vw;
  margin: 10vh auto;
  background: white;
  padding: 0;
  outline: none;
}

.ld-hero:before {
  content: "";
  position: absolute;
  top: 10%;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, transparent 0%, black 80%);
  z-index: 0;
}
.ld-hero:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 10%;
  background: linear-gradient(to top, transparent 0%, black 80%);
  z-index: 0;
}

.button {
  --background-color: white;
  --border-color: linear-gradient(to bottom right, red, blue);
  --border-width: 0.5em;
  --edge-size: 0.5em;

  cursor: pointer;
  background: var(--background-color);
  padding: 0.5em 1.5em;
  border: 0;
  position: relative;
  isolation: isolate;
  display: inline-grid;
  place-content: center;
  clip-path: polygon(
    var(--edge-size) 0%,
    100% 0,
    100% calc(100% - var(--edge-size)),
    calc(100% - var(--edge-size)) 100%,
    0 100%,
    0% var(--edge-size)
  );
  transition: color 250ms;
}
